.app-containers[data-v-6da287a5] {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 275px);
  border: 1px solid #e6e6e6;
  position: relative;
}
.top_btns[data-v-6da287a5] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}