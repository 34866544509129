/* ::-webkit-scrollbar {
    width: 1px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
    background: rgba(17, 16, 16, 0.13);
    -webkit-box-shadow: rgba(0, 0, 0, 0.9);
    box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #efefef;
} */
.tableClassIn .is-last-column {
  border-right: 1px solid #e0d6d6 !important;
}