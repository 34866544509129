@charset "UTF-8";
.searchBtn[data-v-7a3a6d28] {
  text-align: center;
}
.searchBtn button[data-v-7a3a6d28] {
  margin-right: 5px;
}
.top_btns[data-v-7a3a6d28] {
  margin-bottom: 20px;
}
.top_btns .left_box[data-v-7a3a6d28] {
  float: left;
  width: 100%;
}
.el-select[data-v-7a3a6d28] {
  margin: 0 !important;
  width: 120px !important;
}
.elTree[data-v-7a3a6d28] {
  width: 100%;
  height: 500px;
  overflow: scroll;
  border: 1px solid #ede5e5;
  margin-top: 10px;
}
[data-v-7a3a6d28]::-webkit-scrollbar {
  width: 8px;
}

/* 滚动槽 */
[data-v-7a3a6d28]::-webkit-scrollbar-track {
  box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
[data-v-7a3a6d28]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(17, 16, 16, 0.13);
  box-shadow: rgba(0, 0, 0, 0.5);
}
[data-v-7a3a6d28]::-webkit-scrollbar-thumb:window-inactive {
  background: #efefef;
}
.qzfPeriod[data-v-7a3a6d28] {
  width: calc(50% - 32px) !important;
}