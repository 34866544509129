.top_right_btns i[data-v-b5548e56] {
  margin: 0 5px;
  cursor: pointer;
  color: #f15a24;
  font-size: 18px;
  line-height: 24px;
}
.top_btns .left_search[data-v-b5548e56] {
  float: left;
  width: 66%;
}
.top_btns .right_btns[data-v-b5548e56] {
  float: right;
  width: 34%;
  text-align: right;
}
.dialog_box .item[data-v-b5548e56] {
  display: inline-block;
  width: calc(33.3333333333% - 6px);
}
.dwhsDialog .el-dialog[data-v-b5548e56] {
  text-align: left;
}