ul li[data-v-706c5b43] {
  width: calc(100% - 20px);
  height: 80px;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}
.bottom .spanItem[data-v-706c5b43] {
  border-radius: 15px;
  background-color: var(--themeColor, #17a2b8);
  color: white;
  font-size: 11px;
  font-weight: 400;
  padding: 3px 6px;
  margin-left: 15px;
  position: absolute;
  top: 35px;
}
.bottom .spanItemqq[data-v-706c5b43] {
  border-radius: 15px;
  background-color: #c4c3c3;
  color: white;
  font-size: 11px;
  font-weight: 400;
  padding: 3px 7px;
  margin-left: 15px;
  position: absolute;
  top: 35px;
}
.buttonHs[data-v-706c5b43] {
  position: absolute;
  border-radius: 3px !important;
  top: 24px;
  right: 7px;
}
.app-container[data-v-706c5b43] {
  padding: 11px 16px;
  width: 100%;
  min-height: 90px;
  border: 1px solid #e6e6e6;
  border-bottom: none;
}
.card-style[data-v-706c5b43] {
  width: 19%;
  height: 70px;
  background-color: #d8ecff;
}
.methodStyle[data-v-706c5b43] {
  color: #333;
}