.zangu-container .title[data-v-15ad795c] {
  text-align: center;
  font-size: 22px;
}
.zangu-container .header[data-v-15ad795c] {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}
.zangu-container .bor_top[data-v-15ad795c] {
  width: 20%;
  border-top: 1px solid #c2c2c2;
}
.zangu-container table[data-v-15ad795c] {
  width: 100%;
  text-align: center;
}
.zangu-container table td[data-v-15ad795c],
.zangu-container table th[data-v-15ad795c] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
}
.zangu-container table th[data-v-15ad795c]:nth-child(1),
.zangu-container table td[data-v-15ad795c]:nth-child(1) {
  border-left: 1px solid #c2c2c2;
}
.zangu-container table .tit[data-v-15ad795c] {
  height: 25px;
  line-height: 25px;
  display: block;
}
.zangu-container table .money_unit[data-v-15ad795c] {
  text-align: center;
  line-height: 22px;
  height: 22px;
  font-size: 14px;
  border-top: 1px solid #dadada;
  display: flex;
}
.zangu-container table .money_unit span[data-v-15ad795c] {
  height: 100%;
  flex: 1;
  width: 18px;
}
.zangu-container table .td-money[data-v-15ad795c] {
  width: 15%;
  text-align: center;
  background-image: url("http://etax.qizhangfang.net/template/eaccount/line1.png");
  background-repeat: repeat-y;
  width: 220px;
}
.zangu-container table .td-money input[data-v-15ad795c] {
  border: 1px solid #f15a24;
  width: 100%;
  height: 45px;
}
.zangu-container table .td-money > div[data-v-15ad795c] {
  width: 100%;
  text-align: right;
  letter-spacing: 15px;
  position: relative;
  right: -6px;
  height: 100%;
  line-height: 45px;
  font-size: 14px;
}
.zangu-container table .td-money > div span[data-v-15ad795c] {
  height: 100%;
  flex: 1;
  width: 18px;
}
.zangu-container table tbody tr td[data-v-15ad795c] {
  width: 150px;
  height: 45px;
}