.el-select[data-v-534f823a] {
  margin-left: 0px;
}
.app-containers[data-v-534f823a] {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 275px);
  border: 1px solid #e6e6e6;
  position: relative;
}